@import '~antd/dist/antd.variable.less';

.w100 {
  width: 100%;
}

:root {
  --base-font: 2px;
  --base-gap: 8px;
  --base-height: 4px;

  --red: #ec473c;
  --red-33: #ffebeb;
  --black-50: #808080;

  font-family: 'Manrope', sans-serif;
}

@media (max-width: 768px) {
  :root {
    --base-font: 1.8px;
    --base-gap: 6px;
    --base-height: 3px;
  }
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

/* fonts */
@font-face {
  font-family: 'RF Dewi';
  src: url('./styles/fonts/RFDewi-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@primary-color: #233AD8;@link-color: #233AD8;@border-radius-base: 4px;@font-family: "Manrope", sans-serif;@text-color-secondary: #808080;@btn-primary-bg: #233AD8;@btn-disable-color: #fff;@btn-disable-bg: #99B7FF;@form-item-margin-bottom: 44px;@input-height-lg: 64px;@input-padding-horizontal-lg: 20px;@input-placeholder-color: #808080;@input-bg: 1;